import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import Homepage from './components/Homepage';

function App() {
  return (
    <Box bg="korea.black" minH="100vh" display="flex" alignItems="center" justifyContent="center">
      <Container maxW="container.md">
        <Homepage />
      </Container>
    </Box>
  );
}

export default App;
