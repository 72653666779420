import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import './styles.css';

const colors = {
  korea: {
    red: '#C60C30',
    blue: '#003478',
    white: '#FFFFFF',
    black: '#000000',
  },
};

const theme = extendTheme({ colors });

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById('root')
);
