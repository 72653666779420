import React, { useState } from 'react';
import { 
  Box, 
  Text, 
  Select, 
  Input, 
  Button, 
  Spinner, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  Image, 
  Progress, 
  useClipboard 
} from '@chakra-ui/react';
import { ArrowForwardIcon, CopyIcon } from '@chakra-ui/icons';
import '@fontsource/noto-sans-kr'; // Import Noto Sans KR from Google Fonts
import '../styles.css';

const Homepage = () => {
  const [loading, setLoading] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [adProgress, setAdProgress] = useState(0);
  const [koreanName, setKoreanName] = useState("");
  const [showResult, setShowResult] = useState(false);
  const { hasCopied, onCopy } = useClipboard(koreanName);

  // Hardcoded options based on your database
  const genders = ["male", "female"];
  const agespans = [
    "0-5", "5-10", "10-15", "15-20", "20-25", "25-30",
    "30-35", "35-40", "40-45", "45-50", "50-55"
  ];
  const commonnessOptions = ["common", "uncommon", "very common", "very uncommon"];

  const handleSubmit = async () => {
    const gender = document.querySelector('select[name="gender"]').value;
    const agespan = document.querySelector('select[name="agespan"]').value;
    const commonness = document.querySelector('select[name="commonness"]').value;
    const name = document.querySelector('input[name="name"]').value;

    if (!gender || !agespan || !commonness || !name) {
      alert("Please fill in all fields.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://getyourkname.com/name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ gender, agespan, commonness, name }),
      });

      const result = await response.json();

      if (response.ok) {
        setKoreanName(result.korean_name);
      } else {
        setKoreanName("No matching name found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setKoreanName("An error occurred");
    } finally {
      setLoading(false);
      setShowAd(true);
      let progress = 0;
      const interval = setInterval(() => {
        progress += 20;
        setAdProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setShowAd(false);
          setShowResult(true);  // Show the result modal after the ad
        }
      }, 1000);
    }
  };

  return (
    <Box
      minH="100vh"
      py={10}
      bg="white"
      color="black"
      fontFamily="AdventPro" // Apply AdventPro font to all text
    >
      <Text 
        fontSize="4xl" 
        color="#CD2E3A" 
        textAlign="center" 
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)" // Drop shadow for the header text
      >
        Get Your Korean Name
      </Text>
      <Text 
        fontSize="xl" 
        color="#0047A0" 
        textAlign="center" 
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)" // Drop shadow for the subtitle text
      >
        당신의 이름을 알아보세요
      </Text>

      <Box textAlign="center" mt="4">
        <Text 
          color="#0047A0" 
          mb={2} 
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)" // Drop shadow for dropdown headers
        >
          Gender
        </Text>
        <Select 
          name="gender"
          placeholder="Select gender" 
          width="50%" 
          margin="auto" 
          borderColor="#CD2E3A"
          fontFamily="AdventPro"
          _placeholder={{ color: "#0047A0", fontFamily: "AdventPro" }}
        >
          {genders.map((gender) => (
            <option key={gender} value={gender}>{gender}</option>
          ))}
        </Select>

        <Text 
          mt="4" 
          color="#0047A0" 
          mb={2} 
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)" // Drop shadow for dropdown headers
        >
          Age Span
        </Text>
        <Select 
          name="agespan"
          placeholder="Select age span" 
          width="50%" 
          margin="auto" 
          borderColor="#CD2E3A"
          fontFamily="AdventPro"
          _placeholder={{ color: "#0047A0", fontFamily: "AdventPro" }}
        >
          {agespans.map((agespan) => (
            <option key={agespan} value={agespan}>{agespan}</option>
          ))}
        </Select>

        <Text 
          mt="4" 
          color="#0047A0" 
          mb={2} 
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)" // Drop shadow for dropdown headers
        >
          Commonness
        </Text>
        <Select 
          name="commonness"
          placeholder="Select commonness" 
          width="50%" 
          margin="auto" 
          borderColor="#CD2E3A"
          fontFamily="AdventPro"
          _placeholder={{ color: "#0047A0", fontFamily: "AdventPro" }}
        >
          {commonnessOptions.map((common) => (
            <option key={common} value={common}>{common}</option>
          ))}
        </Select>

        <Text 
          mt="4" 
          color="#0047A0" 
          mb={2} 
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)" // Drop shadow for dropdown headers
        >
          Your Name
        </Text>
        <Box width="50%" margin="auto" mb={6}>
          <Input 
            name="name"
            placeholder="Enter your name" 
            borderColor="#CD2E3A"
            fontFamily="AdventPro"
            _placeholder={{ color: "#0047A0", fontFamily: "AdventPro" }}
          />
        </Box>

        <Box width="50%" margin="auto">
          <Button 
            mt="4" 
            bgGradient="linear(to-r, #CD2E3A, #E53E3E)" 
            color="white"
            fontFamily="AdventPro"
            _hover={{ bgGradient: "linear(to-r, #E53E3E, #CD2E3A)", transform: "scale(1.05)" }}
            onClick={handleSubmit}
            rightIcon={<ArrowForwardIcon />}
            width="100%"
          >
            Get Your K Name
          </Button>
        </Box>
      </Box>

      <Modal isOpen={loading} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display="flex" justifyContent="center" alignItems="center">
            <Spinner size="xl" color="#CD2E3A" />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showAd && adProgress < 100} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent maxW="100vw" maxH="100vh" bg="transparent">
          <ModalBody display="flex" justifyContent="center" alignItems="center" p={0}>
            <a href="https://zerodaythegame.com/" target="_blank" rel="noopener noreferrer" style={{ width: '100%', height: '100%' }}>
              <Image src="/reklam.png" alt="Advertisement" objectFit="cover" width="100%" height="100%" />
            </a>
          </ModalBody>
          <Box position="absolute" bottom="0" width="100%">
            <Progress value={adProgress} size="xs" colorScheme="pink" />
          </Box>
        </ModalContent>
      </Modal>

      <Modal isOpen={showResult} onClose={() => setShowResult(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={6}>
            <Text fontSize="2xl" fontFamily="AdventPro">Your Korean Name is:</Text>
            <Text fontSize="4xl" fontWeight="bold" mt={4} mb={6}>{koreanName}</Text>
            <Button onClick={onCopy} leftIcon={<CopyIcon />} colorScheme="blue" variant="solid">
              {hasCopied ? "Copied!" : "Copy"}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Homepage;
